import React from "react";
import { Typography, Space } from "antd";
import { ClockCircleOutlined, EnvironmentOutlined } from "@ant-design/icons";
const { Text, Paragraph, Title } = Typography;
export default function Introduction() {
  return (
    <div style={{ textAlign: "center" }}>
      <Paragraph>
        <ClockCircleOutlined /> JW Marriott Essex House, 160 Central Park West
        <br />
        <EnvironmentOutlined /> Monday, April 7, 2025, 6pm-9pm
      </Paragraph>
      <Title level={4}>HONORING</Title>
      <div
        // direction="horizontal"
        // size={"large"}
        // align="start"
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Paragraph className="introduction-text">
          {/* Hospitality Award <br /> */}
          <Text strong> Lindsey Ueberroth</Text>
          <br />
          CEO <br />
          Preferred Hotels
        </Paragraph>
        <Paragraph className="introduction-text">
          {/* Humanitarian Award <br /> */}
          <Text strong>Susan L. Birnbaum</Text>
          <br />
          President & CEO <br />
          NYC Police Foundation
        </Paragraph>
      </div>
    </div>
  );
}
